import React , {useState,useEffect} from 'react';

//import header data
import {headerData} from "../data";

// import components
import Nav from './Nav';
import NavMobile from "./NavMobile";
import Socials from "./Socials";
//import nav from data

import {navData} from '../data';

//icon
import {TiThMenuOutline} from "react-icons/ti";

import LogoAppe from '../img/header/logoAppe.jpg';



const Header = () => {

  //logo from headerData
  const {logo} = headerData;

   const {items} = navData;

  //header state
  const  [isActive, setIsActive] = useState(false);

  //nav mobile state
  const  [navMobile, setNavMobile] = useState(false);

  useEffect(() =>{

    window.addEventListener('scroll', ()=>{
      window.scrollY > 50 ? setIsActive(true) : setIsActive(false);
    })
  })

  // destructuration
  return <header className={`${isActive? 'h-[100px] lg:h-[110px] shadow-lg' : ' h-[120px] lg:h-[150px]'} 
                       fixed bg-white left-0 right-0  z-10 max-w-[1920px] w-full mx-auto transition-all duration-300 `}>
            <div className='flex justify-between items-center h-full pl-[50px] pr-[60px]'>
              {/* logo */}
              <a href="/">
                <div className='flex flex-col justify-center items-center'>
                  <img src={LogoAppe} className='w-20 h-20 ' alt=""/>
                  <span className='text-[10px]  flex justify-center items-center '><span className='text-[#37a7d5] font-bold '>A</span>ssociation des <span className='text-[#37a7d5] font-bold  ml-1'> P</span>rofessionnels de la <span className='text-[#37a7d5] font-bold ml-1'> P</span>etite <span className='text-[#37a7d5] font-bold ml-1'> E</span>nfance</span>
                </div>
               
              </a>
              {/* nav initially hidden - show on desktop */}
              <div className='hidden xl:flex'>
                <Nav/>
              </div>
              {/* nav menu btn - show by default */}
              <div
                    onClick={()=>setNavMobile(!navMobile)}
                   className='xl:hidden  absolute right-[5%] bg-dark text-white p-2 rounded-md cursor-pointer '>
                <TiThMenuOutline className='text-3xl'/>
              </div>
              {/* nav mobile - show by default hidden on desktop */}
              <div className={` ${navMobile ? 'max-h-full':'max-h-0'} ${
                  isActive
                      ? 'top-[100px] lg:top-[110px] ' 
                      : 'top-[120px] lg:top-[150px]'} fixed bg-white  w-full h-full left-0  -z-10 
                      transition-all duration-300`}>
               {/*  <NavMobile/> */}
 
                 <nav className='w-full h-full flex flex-col justify-evenly overflow-hidden bg-white'>
                      <ul className='flex flex-col justify-center items-center gap-y-6 py-6'>
                        {
                          items.map((item,index) => {
                            return (
                              <div onClick={()=>setNavMobile(!navMobile)}>
                                  <li key={index}>
                                    <a className='text-2xl font-primary uppercase' href={item.href}>{item.name}</a>
                                </li>
                              </div>
                                
                            )
                          })
                        }
                      </ul>
                      <div className='text-2xl'>
                        <Socials/>
                      </div>
                    </nav>;

              </div>
              {/* socials icon */}
             {/*  <div className='hidden xl:flex'><Socials/></div> */}
            </div>
         </header>;
};

export default Header;
