import React from 'react';
import {fadeIn} from "../variants";

import {motion} from "framer-motion";



const About = () => {

  return <section id="about" className='lg:py-5 xl:mb-5 scroll-mt-28'>
               <div className=" container mx-auto">
                 <div className='flex flex-col lg:flex-row gap-x-[70px] items-center'>

                   {/*  text */}
                   <motion.div
                       variants={fadeIn('up')}
                       initial="hidden"
                       whileInView={'show'}
                       viewport={{once:false, amount:0.1}}

                       className='flex-1 h-full w-full xl:mt-10'>
                     <h2 className='h2'>Notre histoire:</h2>
                     <div className='flex flex-col   '>
                       <div className='max-w-[900px] text-grey'>
                         <p className='mb-6'>Notre Association des Professionnels de la Petite Enfance TCHAD , en abrégé (A.P.P.E. TCHAD) est une association à but non lucratif. Elle a été créée le 20 février 2004 dans la commune de Bron (France). Elle est née de l'idée d'une part de créer une crèche-garderie permettant aux mères adolescentes de garder leurs enfants et d'autre part de donner une formation professionnelle à ces jeunes femmes.</p>
                         <p className='mb-9 text-black font-primary'>Historique de l’association :</p>
                         <p className='mb-6'>
                              Catherine TAMYAMBA, présidente de l’association APPE de 2004 à 2017, est née au Tchad dans un village près de Sarh où elle a vécu jusqu’à son adolescence. Elle est ensuite venue vivre en France où elle exerce aujourd’hui la profession d’auxiliaire de puériculture. Restée fortement impliquée et préoccupée par la situation des jeunes mamans de sa ville natale, elle a décidé d’agir en créant en 2004 l’Association des Professionnels de la Petite Enfance (A.P.E).<br/><br/>

                              L'élaboration du projet a mis du temps et, par manque de financements, ne s'est pas concrétisée les premières années. L'association est donc restée inactive un certain temps.<br/><br/>

                              En été 2009, Toussaint HALTHODJIM, originaire aussi de la ville de Sarh, voulait créer une association et un projet pour venir en aide aux jeunes mamans et surtout de garder leurs enfants pendant leurs diverses préoccupations (reprise de l'école, travail, activités génératrices de revenus etc.). C'est alors que Catherine TAMYAMBA lui parle de son association. Ils décident donc de raviver l’A.P.E qui deviendra par la suite l'A.P.P.E pour l’Association des Professionnels de la Petite Enfance.<br/><br/>

                              De 2010 à 2011, de nouveaux membres se sont impliqués, une association pilote (relai) a été créée à Sarh, elle est dénommée Association Mère Enfant (A.M.E) et reconnue par les autorités locales le 09/09/2011.<br/><br/>
                              En 2012, l'acquisition de subventions a permis de se projeter réellement dans la réalisation du projet.<br/><br/>
                              EN 2014, des démarches ont été effectuées pour que l'A.P.P.E soit reconnue d'intérêt général, lui permettant de délivrer des reçus fiscaux pour les dons.<br/><br/>

                          </p>
                         <p className='mb-9 text-black font-primary'>L’association a pour objectifs :</p>
                         <ul className="list-disc ml-24">
                           <li >Prendre en charge les mères adolescentes et leurs enfants.</li>
                           <li >Assurer une prise en charge satisfaisante des élèves et adultes par des professionnels qualifiés.</li>
                           <li >Construire des bâtiments pédagogiques (crèches, écoles maternelles et primaires, centre de formation ...) servant à accueillir les élèves et adultes dans de bonnes conditions d'éducation, d'instruction, d'hygiène et de santé.</li>
                           <li >Contribuer en conseils et orientations pour l’amélioration des conditions de mode de garde d’enfants</li>
                           <li >Créer et entretenir des réseaux de partenariat ou du parrainage entre les structures d’encadrement et de garde des enfants du Tchad et d'autres pays</li>
                         </ul>
                         <div className='mb-9 text-black mt-10 '>
                          
                              <div ><span className='font-primary'>Membres du bureau de l’association APPE </span>(<span className='text-[#37a7d5]'>  élus lors de l'AG du 21/03/2021 et ré-élus lors de l'AG du 07/05/2022 </span>)</div>
                            
                          </div>
                         <ul className="list-disc ml-24">

                           <li >Présidente: <span className='font-semibold'>Toussaint HALTHODJIM</span></li>
                           <li >Vice-Présidente: <span className='font-semibold'>Catherine TAMYAMBA</span></li>
                           <li >Trésorière: <span className='font-semibold'>Chantal GODINEAU</span></li>
                           <li >Trésorier Adjoint: <span className='font-semibold'>Fulbert MBAILLASSEM</span></li>
                           <li >Secrétaire: <span className='font-semibold'>Tamian NINGAYO</span></li>
                           <li >Secrétaire Adjointe: <span className='font-semibold'>Sabine TAMYAMBA</span></li>
                           <li >Chargé des relations APPE/AME: <span className='font-semibold'>Jacob NGONGAR</span></li>
                           <li >Chargées de l’événementiel: <span className='font-semibold'>Sylvie GODINEAU; Cendra NGONGAR</span></li>

                         </ul>
                       </div>
                    
                       <div className='mt-10'>
                         <p className='mb-9 text-black font-primary'>Création de l’Association Mère Enfant, partenaire local </p>
                         <p > L’Association Mère Enfant <span className='font-bold'>A.M.E</span> a été reconnue par la  préfecture de Sarh en 2011. C’est l’antenne locale de l’Association APPE, elle sera opérationnelle dès le commencement du projet.</p>
                         <p >Voici une liste non exhaustive des permières personnes membres de l’AME:</p>
                         <ul className="list-disc ml-24">
                           <li >Présidente: <span className='font-semibold'>Sœur Rosalie NARADOUM</span></li>
                           <li >trésorier : <span className='font-semibold'>Olivier MADJADOUM</span></li>
                           <li >Membre active: <span className='font-semibold'>Soeur MARIAM</span></li>
                           <li >Membre active: <span className='font-semibold'>Louise NELDITA</span></li>
                           <li >Membre active: <span className='font-semibold'>Colette BENOUDJI</span></li>
                           <li >Membre active: <span className='font-semibold'>Simone YOYA</span></li>
                         </ul>

                         
                         <p >Chaque année scolaire, le personnel de la structure (crèche et école) :</p>
                         <ul className="list-disc ml-24">
                           <li >réalise des achats pour l’achat/renouvellement des matériels pédagogiques et didactiques (livres, craies, cahiers, règles, stylos, rames de feuilles etc.).</li>
                           <li >envoie les  puéricultricesde la crèche à une formation de recyclage rendue obligatoire par les autorités régionales de la Famille et de l'Enfance</li>
                           <li >intègre de nouveaux bénévoles pour des stages</li>
                           <li >nous envoie des rapports et bilans (de fonctionnement, financiers, moral etc.)</li>
                         </ul>

                       </div>
                       <div className='mt-10'>
                               <p className='mb-9 text-black font-primary'>Chiffres</p>
                               <div className='flex flex-col  lg:flex-row xl:justify-between '>

                                <div className='flex flex-col justify-between items-center'>
                                  <div class=" text-[24px] rounded-full w-[150px] h-[150px] lg:h-[150px]  lg:w-[150px] bg-slate-100  flex justify-center items-center ">
                                  238
                                  </div>
                                  <div className="  tracking-[1.2px] text-center">
                                   Elèves
                                  </div>
                                </div>
                               
                                <div className='flex flex-col justify-between items-center'>
                                  <div class=" text-[24px] rounded-full w-[150px] h-[150px] lg:h-[150px]  lg:w-[150px] bg-slate-100  flex justify-center items-center ">
                                    2 
                                  </div>
                                  <div className=" tracking-[1.2px] text-center">
                                   Ecoles construites <br/>
                                  </div>
                                </div>

                                <div className='flex flex-col  justify-between items-center'>
                                  <div class=" text-[24px] rounded-full w-[150px] h-[150px] lg:h-[150px] lg:w-[150px] bg-slate-100  flex justify-center items-center ">
                                    9 
                                  </div>
                                  <div className="  tracking-[1.2px] text-center">
                                   Enseignants
                                  </div>
                                </div>

                               </div>
                       </div>
                     </div>
                     
                   </motion.div>

                 </div>
               </div>
         </section>;
};

export default About;
