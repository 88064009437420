import React from 'react';

import {partenerData} from "../data";


const Partners = () => {

    
    return <section >

               <div className=" container mx-auto mb-8 lg:mb-20 scroll-mt-28" id="partners">
                    <h2 className='h2 mt-10'>Nos Partenaires</h2>
                    <div className='flex flex-col justify-center items-center mt-20'>
                       <div className='grid grid-cols-1 lg:grid-cols-3 md:gap-x-[70px] w-full  '>

                        {

                            partenerData.map((partener,index)=>{
                                  
                            return <div key={index} className=' mb-5' >
                                        <a href={partener.href}>
                                            <img src={partener.logo} className='w-[200px] h-[150px]  object-contain ' alt=""/>
                                        </a>   
                                   </div>
                           })                         

                        }
                    
                    </div>
                    </div>
               </div>
              

    </section>
};

export default Partners;