import React from 'react';
import {footerData} from "../data";

import {motion} from "framer-motion";

import {fadeIn} from "../variants";

const  staggerContainer = {

  hidden : {},

  transition: {
    staggerChildren:0.3,
    delayChildren:0.2,

  }

}

const Footer = () => {
  const {about,partenerLocal, links,newsletter} = footerData;
  return <footer className='section bg-dark mt-20'>
    <div className="container mx-auto">
          {/*  grid */}
           <motion.div

               variants={staggerContainer}
               initial="hidden"
               whileInView={'show'}
               viewport={{once:false, amount:0.1}}

               className='text-white flex flex-col lg:flex-row
                         lg:justify-between
                         gap-x-5
                          gap-y-14'>
             {/*  Siege Social Appe */}
             <motion.div
                 variants={fadeIn('up')}

                 className='flex-1 flex flex-col gap-y-6'>
                      {/*  title */}
                        <div className='font-primary text-xl uppercase tracking-[0.08em]'>{about.title}</div>
                      {/*  subtitle */}
                      <div className='leading-relaxed text-[#dbdbdb]'>{about.subtitle}</div>
                      {/*  address phone et email */}
                      <div className=' flex flex-col gap-y-4 font-semibold text-[#dbdbdb]' >
                        {/*  address  */}
                        <div className='flex items-center gap-x-[10px]'>
                          <div>{about.address.icon}</div>
                          <div>{about.address.name}</div>
                        </div>
                        {/*  phone  */}
                        <div className='flex items-center gap-x-[10px]'>
                          <div>{about.phone.icon}</div>
                          <div>{about.phone.number}</div>
                        </div>
                        {/*  email  */}
                        <div className='flex items-center gap-x-[10px]'>
                          <div>{about.email.icon}</div>
                          <div>{about.email.address}</div>
                        </div>
               </div>              
             </motion.div>
            {/*  Partenaire Local  */}
            <motion.div
                 variants={fadeIn('up')}

                 className='flex-1 flex flex-col gap-y-6'>
                      {/*  title */}
                        <div className='font-primary text-xl uppercase tracking-[0.08em]'>{partenerLocal.title}</div>
                      {/*  subtitle */}
                      <div className='leading-relaxed text-[#dbdbdb]'>{partenerLocal.subtitle}</div>
                      {/*  address phone et email */}
                      <div className=' flex flex-col gap-y-4 font-semibold text-[#dbdbdb]' >
                        {/*  address  */}
                        <div className='flex items-center gap-x-[10px]'>
                          <div>{partenerLocal.address.icon}</div>
                          <div>{partenerLocal.address.name}</div>
                        </div>
                        {/*  phone  */}
                        <div className='flex items-center gap-x-[10px]'>
                          <div>{partenerLocal.phone.icon}</div>
                          <div>{partenerLocal.phone.number}</div>
                        </div>
                        {/*  email  */}
                       {/*  <div className='flex items-center gap-x-[10px]'>
                          <div>{partenerLocal.email.icon}</div>
                          <div>{partenerLocal.email.address}</div>
                        </div> */}
               </div>              
             </motion.div>
             {/*  links */}
             <motion.div
                 variants={fadeIn('up')}

                 className='flex-1 flex flex-col '>
               <div className='font-primary text-xl uppercase tracking-[0.08em] mb-6'>{links.title}</div>
               <ul className='flex flex-col gap-y-4   text-[#dbdbdb]'>
                 {
                   links.items.map((item,index) => {
                     const  {href,name} = item;
                     return <li key={index}>
                        <a href={href} className='hover:text-white transition'>{name}</a>
                     </li>

                   })

                 }
               </ul>
             </motion.div>
             {/*  newsletter */}
            {/*  <motion.div
                 variants={fadeIn('up')}
                 className='flex-1 '>
               <div className='font-primary text-xl uppercase tracking-[0.08em] mb-6  text-[#b6b6b6] '>{newsletter.title}</div>
               <div className='leading-relaxed text-[#dbdbdb] mb-9  '>{newsletter.subtitle}</div>
               <form className='flex justify-between items-start border-b text-[#b6b6b6]  ' action="">
                 <input className='outline-none placeholder:text-base placeholder:uppercase bg-transparent pb-2' placeholder={newsletter.form.placeholder} type="text"/>
                 <button className='text-2xl cursor-pointer ' type='submit'>{newsletter.form.icon}</button>
               </form>
             </motion.div> */}
           </motion.div>
    </div>
  </footer>;
};

export default Footer;
