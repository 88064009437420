import React from 'react';

//import hero data 
import {heroData} from '../data';

//import motion
import {motion} from 'framer-motion';

//import fadeIn
import {fadeIn} from '../variants';
import { useTypewriter, Cursor } from 'react-simple-typewriter'

const container = {

    hidden:{},
    transition: {
        staggerChildren: 0.1 ,
        delayChildren: 0.6
    }
}

const Hero = () => {

  const [text, count] = useTypewriter({
    words: [
        "La pétite enfance",   
        "et l’accès à l’éducation",   
        "sont nos priorités.",     
      ],
    loop: true,
   delaySpeed: 2000
  });
  
  // destruction
  const {title,subtitle,btnIcon,btnText} = heroData;
  return <section className='bg-hero bg-cover bg-center min-h-[40ph]
  lg:h-[948px] bg-no-repeat relative mt-[120px]
  lg:mt-[150px]'>
  <motion.div
      variants={container}
      initial='hidden'
      whileInView={'show'}
      className='container mx-auto min-h-[40ph] lg:h-full flex
  items-center justify-center justify-items-end '>

     {/*   text & btn */}
     <div className='text-white text-center lg:text-left  '>
       {/*   text */}
        <motion.h1
            variants={fadeIn('down')}

            className='h1'
            
            >{title}
        </motion.h1>


      <motion.p 
        className='text-white
        text-[40px]
        lg:text-[60px]
        leading-[1.1] font-tertiary -tracking-[1.5px]  mb-8'

         
         >
        {/* {subtitle} */}
        <h1 className='text-5xl lg:text-6xl font-semibold px-2'>
          <span>{text}</span>
          <Cursor cursorColor='red' />
        </h1>
        </motion.p>
       
       {/*  btn */}
        <div>
        <a href="#about">
       <button className='btn btn-sm lg:btn-lg btn-outline mx-auto lg:mx-0'>
            {btnText} <div>{btnIcon}</div></button></a>
        
       </div> 
     </div>



  </motion.div>

  </section>;
};

export default Hero;
