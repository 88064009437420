import React from 'react';


const Projects = () => {
    return <section id="projets" className='scroll-mt-28'>
               
               <div className='container mx-auto mb-20'>

                   <div className='flex flex-col'>
                        <h2 className={"h2 max-w-[370px] lg:mb-10 "}>Projets</h2>
                        <div className='flex flex-col  text-grey mt-3'>
                                <h3 className='h3 font-bold'>Projet « Agrandissement d'une école dans un quartier défavorisé de SARH au TCHAD.»</h3>
                                <ul className="list-disc ml-10" >
                                    <li><p>2020 - 2022 : initié en septembre 2020, le projet a démarré en avril 2021 et est terminé en octobre 2022.
                                        </p></li>
                                        <li><p>Il a été soutenu financièrement par la Guilde l’Agence des 
                                            Micro-Projets à hauteur de 10.000 €, la Fondation DEVOTEAM S.A (6.000€ : 3.000€ 
                                            en 2021 et 3.000€ en 2022) , le Mécénat du Groupe Bolloré (5.000€) , Apport APPE (5000 euro)
                                            </p>
                                       </li>
                                    <li>
                                            <p>  Réalisation d'un bâtiment de 4 classes en dur et meublé.
                                            </p>
                                    </li>
                                    <li>
                                            <p>  
                                                Ouverture  des classes CE2 et CM1  durant l'année académique 2022-2023
                                            </p>
                                    </li>
                                </ul>
                            </div>
                            <div className='flex flex-col mt-10 text-grey'>
                                <h3 className='h3 font-bold'>Projet « La seconde école » au quartier KEMKIAN, ville SARH au TCHAD.»</h3>
                                <ul className="list-disc ml-10" >
                                    <li><p>2013 : Ouverture de la Crèche au quartier KEMKIAN.
                                         Deux (02) bâtiments (crèche 85 m2 + une salle de cours 60m2) ont été construits au quartier KEMKIAN, quartier très défavorisé à la périphérie de la ville de SARH.
                                        </p></li>
                                        <li><p>2017: Construction d’un mur d'enceinte (140m de longueur, 65m de largeur et 2,10m de hauteur avec portail et petite porte d'entrée, toujours dans le au quartier KEMKIAN. Cela assure la sécurité des enfants mais aussi des bâtiments.</p></li>
                                        <li> <p>2018: Ouverture d’une classe de CP1, au sein du 2nd bâtiment</p></li>
                                        <li>  <p>2019: Ouverture d’une classe de CP2 au sein du 2nd bâtiment qui cumule pour l’instant un double niveau CP1/CP1</p></li>
                                        <li> <p>2020: Ouverture d’une classe de CE1 au sein du 1er bâtiment de crèche, dans une salle utilisée autrefois comme bureau du directeur et du personnel.</p></li>
                                        <li>
                                            <p>Le projet a été soutenu financièrement à hauteur de 34641 €  Grâce à plusieurs subventions : <br/>
                                                <ul className="list-none">
                                                <li > Ministère des affaires Etrangères et Européennes France(26000€ )</li>
                                                <li >APPE porteur du projet(3641€)</li>
                                                <li >Département du Rhône(2000€)</li>
                                                <li >Donation Cantonale Ville St Priest(500€) </li>
                                                </ul>
                                            </p>
                                       </li>
                                </ul>
                                
                            </div>
                            <div className='flex flex-col mt-5  text-grey'>
                                <h3 className='h3 font-bold' >Projet « Amélioration des conditions de scolarisation en école primaire des filles du village de Modelé/MANDOUL, au Tchad ».</h3>
                                <div>
                                <p>2019 - 2020: Construction d’un bâtiment (189m2) de 3 classes en dur, d’1 forage d’eau potable et des latrines</p>
                                <p>Le projet a été soutenu financièrement par Forum des Organisations Issues de l’Immigration (FORIM/PRA-OSIM),Agence Française de Développement (AFD), Fondation DEVOTEAM à hauteur de 15.000 €. Ces réalisations ont permis de faire de cette école : </p>
                                <ul className="list-disc ml-10" >
                                    <li >Une école de proximité, surtout en faveur des filles.</li>
                                    <li> Un lieu d' enseignement de qualité  visant à intégrer majoritairement les filles pour qu’elles aient l’opportunité de poursuivre ensuite leurs études au collège.</li>
                                </ul>
                                </div>
                            
                            </div>
                        </div>

               </div>


           </section>
}; 

export default Projects;