import React from 'react';


import {motion} from "framer-motion";

import {fadeIn} from "../variants";

import ImgDonate from '../img/donate/donate0.jpg';

const Donate = () => {
    return <section id="donate" className='scroll-mt-28' >
        

                <div className='container mx-auto mb-8 lg:mb-20 w-full ' >
                    <div className='flex flex-col  '>

                    <h2 className={"h2 max-w-[370px] lg:mb-10 mt-10"}>Donation</h2>
                    <div className='flex flex-col lg:flex-row '>

                        <div className='flex-1'>
                        <img src={ImgDonate} className='w-full h-50  object-contain' alt=""/>
                        </div>
                        <div className=' flex-1 p-8'>

                            <p className='mt-2'>
                            Votre don soutient  les missions de APPE au près des enfants.
                            </p>
                            <h3 className='h3 font-bold uppercase mt-5 mb-5 '>Par virement</h3>
                            <p className='ml-10'>
                            <div className=' font-normal '>IBAN  : <span className='font-bold'>FR7120041010071423010Y03895</span> </div>
                            <div className=' font-normal' >BIC : <span  className='font-bold'>PSSTFRPPLYO</span></div>
                            </p>
                            <h3 className=' uppercase h3 font-bold mt-5 mb-5'>Lien cagnotte</h3>
                            <p className='ml-10'>
                            <a 
                                className='text-lg  '
                                href="https://www.helloasso.com/associations/association-des-professionnels-de-la-petite-enfance/collectes/aider-l-ecole-primaire-a-sarh-au-tchad-fonctionnement-achat-de-tables-bancs">
                                Cliquer <span className=' hover:to-blue-500 font-bold'>ici</span>
                            </a>
                            </p>

                        </div>

                    </div>
                  
                    </div>

                  

                </div>


            </section>
};

export default Donate;