import React from 'react';

// import components
import Hero from './components/Hero';
import Header from './components/Header';
import About from './components/About';
import GallerySection from './components/GallerySection';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Copyright from './components/Copyright';


import Events from "./components/Events";
import Projects from "./components/Projects";
import Donate from "./components/Donate";
import Partners from "./components/Partners";





const App = () => {
  return (
    <div className='max-w-[1920px] mx-auto overflow-hidden bg-white'>
      <Header />
      <Hero />
      <About />
      <Projects/>
      <Events/>
      <GallerySection />
      <Partners/>
      <Contact />
      <Donate/>
      <Footer />
      <Copyright />
      {/* <div className='h-[4000px]'></div> */}
    </div>
  );
};

export default App;
