import React from 'react';
import {
    VerticalTimeline,
    VerticalTimelineElement,
  } from "react-vertical-timeline-component";
  import "react-vertical-timeline-component/style.min.css";

  import {eventsData} from '../data';
  import {TiThMenuOutline} from "react-icons/ti";


const Events = () => {
    return <section id="events"  className='scroll-mt-28'>

            <div className='container mx-auto '>
           
                <div className='flex flex-col '>
                    <h2 className={"h2 max-w-[370px] lg:mb-10 mt-10"}>Activités et événements</h2>
                    <div className='flex flex-col  text-grey'>
                        <h3 className='h3 '>Pour soutenir la crèche construite à Sarh et l'Association APPE Tchad, des événements et activités  sont organisés par les membres.</h3>
                        <ul className="list-disc ml-10" >
                                <li>
                                    <p>19 juin 2022 et 21 septembre 2022 :Courses solidaires organisées au collège Gérard PHILIPE à Massy 91300,France.</p>
                                </li>
                                <li>
                                    <p>29 octobre 2022: Evénement APPE à Lyon,France : bonne adhésion des participants bien que peu nombreux avec une bonne rentabilité.<br/>Ces activités et événements ont permis de mobiliser nos sympathisants (dons 2.820 €, événements 1.398,50 €) malgré un contexte économique compliqué.</p>
                                </li>
                                <li>
                                    <p>2019 , Une course solidaire au Collège Jean Jaurès de Villeurbanne ou dans les collèges de Bron</p>
                                </li>
                                <li>
                                    <p>19 avril 2019:  une course solidaire en partenariat avec le collège Gérard Philipe de MASSY, pour l’association APPE.<br/>
                                    Participation de 80 élèves participants et une recette de 503 euros.La course s’est bien déroulée et a été appréciée des élèves, professeurs et membres de l'association.</p>
                                </li>
                                <li>
                                    <p>27 avril 2019: Un événement festif a été organisé, avec des animations variées et un repas.</p>
                                </li>
                                <li>
                                    <p>Ouverture d’une cagnotte en ligne Leetchi pour récolter plus de dons.</p>
                                </li>
                                <li>
                                    <p>14 avril 2018: Organisation d'un événement(repas,tambola,soirée dansante) à la salle Rockamahjou , 34 rue de Bruxelles, 69100 Villeurbanne,France</p>
                                </li>
                            </ul>
                    </div>
                </div>

       {/*          <VerticalTimeline lineColor="">
        {eventsData.map((item, index) => (
          <React.Fragment key={index}>
            <VerticalTimelineElement
              contentStyle={{
                background: "#f3f4f6",
                boxShadow: "none",
                border: "1px solid rgba(0, 0, 0, 0.05)",
                textAlign: "left",
                padding: "1.3rem 2rem",
              }}
              contentArrowStyle={{
                borderRight:"white",
              }}
              date={item.date}
              icon={item.icon}
              iconStyle={{
                background:"white",
                fontSize: "1.5rem",
              }}
            >
              <h3 className="font-semibold capitalize">{item.title}</h3>
              <p className="font-normal !mt-0">{item.location}</p>
              <p className="!mt-1 !font-normal text-gray-700 dark:text-white/75">
                {item.description}
              </p>
            </VerticalTimelineElement>
          </React.Fragment>
        ))}
      </VerticalTimeline> */}

            </div>




    </section>
};
export default Events;